.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  min-height: 85px;
  z-index: 1;
  background-color: #ffffff;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__logo {
    justify-self: flex-start;
    margin-right: auto;

    img {
      height: auto;
      width: 200px;
      @include mq($from: xl) {
        width: 250px;
      }
      @include mq($until: lg) {
        width: 175px;
      }
    }
  }

  &__nav {
    padding: 0 50px;
    width: 45%;
    @include mq($until: lg) {
      padding: 0 30px;
    }
    @include mq($until: md) {
      width: 100%;
      height: auto;
      box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.3);
      background-color: $color-main-opacity;
    }
    @include mq($until: xs){
      padding: 0 15px;
    }
  }

  &__phone {
    width: 30%;
    background-color: $color-extra;
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 55px;
    @include mq($until: lg) {
      padding-left: 30px;
    }
    @include mq($until: md) {
      background-color: unset;
      padding-left: 15px;
      padding-right: 30px;
      width: auto;
    }
    @include mq($until: xs){
      display: none;
    }

    svg {
      fill: $color-font-light;
      margin: 0 10px;
      height: 28px;
      width: auto;
      @include mq($until: md) {
        fill: $color-extra;
      }
    }

    span {
      color: $color-font-light;
      font-weight: $fw-medium;
      @include mq($until: md) {
        color: $color-extra;
      }
    }
  }

  &__menuList {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    @include mq($until: md) {
      justify-content: space-around;
    }
    @include mq($until: xs){
      justify-content: space-between;
    }
  }

  &__menuItem {
    margin-left: 10px;
    padding: 0;
    margin-top: 0;

    @include mq($until: md) {
      margin-bottom: 0;
    }

    @include mq($until: xs){
      margin-left: 0;
    }

    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #000;
    font-weight: $fw-regular;
    font-size: rem(15px);
    @include mq($until: lg) {
      font-size: rem(13px);
    }
    @include mq($until: md) {
      color: #fff;
    }

    &:active, &--active, &--open, &:hover {
      color: $color-extra;
      @include mq($until: md) {
        color: $color-font-light;
      }
    }
  }
}