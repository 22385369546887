.rt {
  &__header, &__row {
    width: 100%;
    display: grid;
    //grid-template-columns: 1.7fr 1fr 1fr 1.7fr 1.5fr 0.8fr 0.8fr;
    grid-template-columns: 1.7fr 1fr 1fr 1.7fr 0.8fr 0.8fr;
    grid-gap: 10px;
    font-family: $font-family-extra;
    padding: 15px;
    @include mq($until: xs) {
      grid-template-columns: 1fr;
    }

    span {
      font-size: rem(14px);
      color: #000;
      @include mq($until: lg) {
        font-size: rem(13px);
      }
      @include mq($until: xs) {
        font-size: rem(16px);
      }
    }
  }

  &__header {
    margin-bottom: 15px;
    @include mq($until: xs) {
      display: none;
    }
  }

  &__item {
    opacity: .3;

    @include mq($until: xs) {
      display: grid;
      grid-template-columns: 120px 1fr;
      align-items: center;
      &:last-of-type{
        display: none;
      }
      &::before {
        content: attr(data-column);
        font-weight: $fw-semi-bold;
        font-size: rem(16px);
      }
    }

    &--wolne, &--status {
      opacity: 1;
    }
  }

  &__row {
    &--even {
      background-color: $color-bg-extra;
    }

    &:hover {
      background-color: $color-main;

      span {
        color: $color-font-light;
      }
    }
  }

  .status {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 5px;
    border-radius: 50%;

    &--wolne {
      background-color: #8dc63f;
    }

    &--zarezerwowane {
      background-color: darkorange;
    }

    &--sprzedane {
      background-color: #b32227;
    }
  }
}