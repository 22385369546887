.house {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-wrap: wrap;

    &-left {
      width: 70%;
      background-color: $color-main;
      padding-top: 45px;
      padding-bottom: 155px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      @include mq($until: lg) {
        padding-bottom: 45px;
      }
      @include mq($until: sm) {
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
      }
      @include mq($until: xs){
          flex-wrap: wrap;
      }
    }

    &-right {
      width: 30%;
      padding-top: 45px;
      @include mq($until: sm){
        width: 100%;
        padding-top: 15px;
      }
    }
  }

  &__header {
    @include mq($until: xs){
      margin-right: 15px;
    }
    h1 {
      color: $color-font-light;
      font-size: rem(34px);
      text-transform: uppercase;
      @include mq($until: lg) {
        font-size: rem(26px);
      }
    }
  }

  &__info {
    margin-top: 10px;
    margin-left: 100px;

    @include mq($until: md) {
      margin-left: 30px;
    }
    @include mq($until: sm) {
      margin-left: 50px;
    }
    @include mq($until: xs){
      margin-left: 0;
      margin-top: 20px;
    }

    p {
      color: $color-font-light;
      font-size: rem(18px);
      font-weight: $fw-light;
      @include mq($until: lg) {
        font-size: rem(14px);
      }
      @include mq($until: sm) {
        margin-bottom: 10px;
      }
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding-left: 30px;
    margin-top: 15px;
    @include mq($until: xs){
      padding-left: 15px;
    }

    strong {
      font-weight: $fw-bold;
      font-size: rem(16px);
      margin-bottom: 40px;
      @include mq($until: md) {
        font-size: rem(14px);
        margin-bottom: 15px;
      }
      @include mq($until: sm){
        font-size: rem(16px);
      }
    }

    .button {
      font-size: rem(16px);
      width: 100%;
    }
  }

  &__floors {
    margin-top: -110px;
    @include mq($until: lg) {
      margin-top: 30px;
    }
  }

  &__content {
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    margin-top: -110px;
    position: relative;
    z-index: 0;
    @include mq($until: sm){
        flex-wrap: wrap;
    }
  }

  &__description {
    padding-top: 160px;
    width: 70%;
    background-color: $color-bg-extra;
    padding-right: 100px;
    @include mq($until: sm){
      width: 100%;
      padding-right: 30px;
    }

    p, span, ul, li {
      font-size: rem(14px);
      color: #000;
      line-height: 1.8;
    }
  }
}

.sidebar {
  width: 30%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-left: 50px;
  padding-top: 160px;
  @include mq($until: sm){
    width: 100%;
    padding: 30px;
  }
  @include mq($until: xs){
    padding: 30px 15px;
  }

  &__button {
    position: absolute;
    bottom: 30px;
    margin-bottom: 0;
    @include mq($until: sm){
      right: 0;
    }
  }
}

.floor {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 60px;
  position: relative;
  z-index: 1;
  @include mq($until: sm){
      flex-wrap: wrap;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &:nth-child(even) {
    align-items: flex-start;

    .floor__rooms {
      order: 2;
      padding-left: 60px;
      @include mq($until: md) {
        padding-left: 30px;
      }
      @include mq($until: sm){
        padding-left: 0;
        order: 0;
      }
    }
  }

  &__rooms {
    width: 40%;
    padding-top: 30px;
    padding-bottom: 0;
    padding-right: 60px;
    @include mq($from: xl) {
      width: calc(100% - 681px);
    }
    @include mq($until: lg) {
      width: 50%;
    }
    @include mq($until: md) {
      padding-bottom: 0;
      padding-right: 30px;
    }
    @include mq($until: sm){
      width: 100%;
      padding-right: 0;
    }
  }

  &__img {
    width: 60%;
    max-width: 681px;
    @include mq($until: lg) {
      width: 50%;
    }
    @include mq($until: sm){
      width: 100%;
    }
  }

  &__header {
    font-size: rem(19px);
    margin-bottom: 20px;
  }
}

.room {
  margin-bottom: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(25px);
    height: rem(25px);
    border-radius: 50%;
    background-color: $color-main;
    color: $color-font-light;
    font-size: rem(16px);
    margin-right: 10px;
  }

  &__name {
    font-size: rem(14px);
    font-weight: $fw-light;
  }
}