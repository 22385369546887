.advantages {
  background-color: $color-bg-extra;
  width: 70%;
  padding-bottom: 110px;
  @include mq($until: sm) {
    width: 100%;
    padding-right: 30px;
  }
  @include mq($until: xs) {
    padding-right: 15px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5.1vw;
    padding-top: 65px;
    padding-bottom: 65px;
    @include mq($until: md) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    border-right: 1px solid #d7e2f1;
    @include mq($until: xs) {
      border: none;
    }

    &:last-of-type {
      border: none;
    }

    img {
      height: 65px;
      width: auto;
      margin-bottom: 30px;
      @include mq($until: lg) {
        height: 50px;
        margin-bottom: 20px;
      }
      @include mq($until: xs) {
        height: 40px;
      }
    }

    span {
      font-family: $font-family-extra;
      font-weight: $fw-bold;
      font-size: rem(17px);
      line-height: 1.3;
      @include mq($until: lg) {
        font-size: rem(14px);
      }
    }
  }

  &--house {
    width: auto;
    background-color: transparent;
    position: sticky;
    top: 15px;
    @include mq($until: sm) {
      padding-bottom: 60px;
    }
    @include mq($until: xs){
      padding-right: 0;
    }

    .advantages__list {
      display: flex;
      flex-direction: column;
      padding-left: 50px;
      padding-top: 0;
      grid-gap: unset;
      @include mq($until: md) {
        padding-left: 0;
      }
      @include mq($until: sm) {
        padding-left: 0;
        flex-direction: row;
        justify-content: space-between;
      }
      @include mq($until: xs) {
        flex-wrap: wrap;
        justify-content: space-around;
      }
    }

    .advantages__item {
      border: none;
      flex-direction: row;
      align-items: center;
      margin-bottom: 50px;
      @include mq($until: lg){
        flex-direction: column;
        align-items: flex-start;
      }
      @include mq($until: xs) {
        width: 40%;
        align-items: center;
        justify-content: center;
      }

      span {
        font-size: rem(14px);
        @include mq($until: xs){
          text-align: center;
        }
      }

      img {
        margin-bottom: 10px;
        height: 50px;
        margin-right: 15px;
        @include mq($from: xl) {
          height: 65px;
        }
        @include mq($until: lg){
          margin-right: 0;
        }
      }
    }
  }
}