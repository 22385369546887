$shadow-hero: 0 0 27px rgba(6, 39, 80, .35);
@keyframes HeroAnimation {
  0% {
    transform: scale(1.3);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hero {
  position: relative;
  max-height: calc(100vh - 85px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
  @include mq($until: md) {
    flex-wrap: wrap;
  }

  &__box {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(to right, rgba(96, 128, 192, 0.8) 0%, #6080c0 30%);
    width: 30%;
    padding-left: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    transition: transform .5s ease-in-out;

    &--hidden {
      transform: translateX(100%);
      @include mq($until: sm) {
        transform: translateX(0);
      }
    }

    @include mq($until: lg) {
      padding-left: 30px;
    }
    @include mq($until: sm) {
      position: relative;
      width: 100%;
      padding: 30px;
      align-items: center;
    }

    &::before {
      content: '';
      background-image: url('../../images/home/waves.png');
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      right: 0;
      bottom: 40%;
      opacity: .6;
      z-index: 1;
      width: 80%;
      height: 5.5vw;
      @include mq($until: lg) {
        bottom: 50%;
      }
      @include mq($until: sm) {
        display: none;
      }
    }
  }

  &__img {
    animation: HeroAnimation 2s;
    animation-delay: .5s;
    animation-fill-mode: both;
  }

  &__header {
    color: $color-font-light;
    text-shadow: $shadow-hero;
    @include mq($until: lg) {
      font-size: rem(26px);
    }
    @include mq($until: sm) {
      margin-bottom: 15px;
      text-align: center;
    }

  }

  &__content {
    @include mq($until: sm) {
      text-align: center;
    }

    .button {
      width: 100%;
      font-weight: $fw-semi-bold;
      box-shadow: $shadow-hero;
      @include mq($until: sm) {
        width: auto;
      }
    }
  }

  &__text {
    color: $color-font-light;
    font-weight: $fw-semi-bold;
    line-height: 1.7;
    font-size: rem(15px);
    padding-top: 30%;
    padding-bottom: 15%;
    text-shadow: $shadow-hero;
    @include mq($until: md) {
      font-size: rem(14px);
    }
    @include mq($until: sm) {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.widget {
  position: relative;
  margin-top: -110px;

  @include mq($until: xs) {
    padding: 0;
  }

  &__text {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 70%;
    &--bottom{
      top: unset;
      bottom: 0;
      @include mq($until: md){
        display: none;
      }
    }
    @include mq($until: sm) {
      width: 100%;
      position: static;
      padding: 0;
    }

    h2 {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 70px;
      color: $color-font-light;
      background-color: $color-main-opacity;
      margin-bottom: 0;
      padding-left: 70px;
      padding-right: 70px;
      font-size: rem(32px);
      text-shadow: $shadow-hero;
      @include mq($from: xl) {
        height: 110px;
        font-size: rem(35px);
      }
      @include mq($until: lg) {
        font-size: rem(28px);
      }
      @include mq($until: md) {
        padding-left: 35px;
        padding-right: 35px;
        height: 80px;
      }
      @include mq($until: sm) {
        height: 60px;
        background-color: $color-main;
      }
      @include mq($until: xs) {
        font-size: rem(24px);
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
}

.houses {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  margin-top: -110px;
  flex-wrap: wrap;

  &__list {
    width: 70%;
    padding-top: 145px;
    @include mq($until: md) {
      width: 100%;
      padding-right: 30px;
      order: 2;
      padding-top: 30px;
    }
    @include mq($until: sm) {
      padding-left: 0;
      padding-right: 0;
    }
    @include mq($until: xs) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  &__box {
    width: 30%;
    background-image: url('../../images/home/tabela-tekst-bg.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding-top: 145px;
    padding-bottom: 145px;
    padding-left: 35px;
    @include mq($until: md) {
      width: 100%;
      padding-left: 30px;
      padding-bottom: 30px;
      order: 1;
      background-color: $color-main;
      background-image: none;
    }
    @include mq($until: xs) {
      padding-left: 15px;
    }

    h2, h3, h4, p {
      color: $color-font-light;
    }

    p {
      line-height: 1.7;
      text-shadow: 0px 0px 15px #000;
      @include mq($until: md) {
        text-shadow: unset;
      }
    }
  }
}

.break {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  &__left {
    width: 70%;
    height: 110px;
    background-color: $color-bg-extra;
    @include mq($until: md) {
      height: 70px;
    }
  }

  &__right {
    width: 30%;
  }
}

.comparison {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;

  @include mq($until: sm) {
    grid-template-columns: 1fr;
  }

  &__house {
    position: relative;

    &:hover {
      .comparison__img-back {
        opacity: 1;
      }
    }
  }

  &__text {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 2;
    background-color: $color-main-opacity;
    width: 50%;
    padding: 20px 35px;

    @include mq($until: md) {
      position: static;
      width: 100%;
      background-color: $color-main;
    }

    &--right {
      left: 0;
      right: unset;
    }

    h3 {
      color: $color-font-light;
      font-size: rem(21px);
      margin-bottom: 0;
      @include mq($until: lg) {
        font-size: rem(18px);
      }
      @include mq($until: sm) {
        font-size: rem(21px);
      }
    }
  }

  &__img-front {
    position: relative;
    line-height: 1;
  }

  &__img-back {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    line-height: 1;
    transition: opacity .3s ease-in-out;

    img {
      height: 100%;
      width: auto;
    }
  }
}

.neighbour {
  background-color: $color-bg-extra;
  padding-top: 110px;
  padding-bottom: 110px;

  @include mq($until: xs) {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0px 40px 50px 40px $color-bg-extra;

    h2 {
      color: $color-main;
      font-size: rem(30px);
      max-width: rem(360px);
      text-align: center;
      @include mq($until: xs) {
        margin-bottom: 0;
      }
    }
  }

  &__photo {
    background-image: url('../../images/home/przerywnik3.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 34vw;
    @include mq($until: lg) {
      background-attachment: unset;
      height: 400px;
      background-size: cover;
      background-position: top right;
    }
  }

  &__content-wrapper {
    box-shadow: 0px -40px 50px 40px $color-bg-extra;
  }

  &__content {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 30px;

    @include mq($from: xl) {
      max-width: 1200px;
    }

    p {
      text-align: center;
      color: #000;
      line-height: 1.7;
    }
  }
}

.fb-news {
  background-color: $color-bg-extra;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 210px;
  flex-wrap: wrap;

  @include mq($until: sm) {
    justify-content: flex-start;
  }

  &__box {
    position: absolute;
    max-width: rem(320px);
    left: 12%;
    z-index: 9;
    @include mq($until: lg) {
      top: 10%;
    }
    @include mq($until: sm) {
      position: static;
      margin-bottom: 30px;
    }

    h2 {
      color: $color-extra;
      font-size: rem(30px);
    }
  }

  &__button {
    background-color: $color-main;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 5px 5px 5px 15px;
    border-radius: 5px;
    transition: background-color .3s ease-in-out;

    &:hover {
      background-color: $color-extra;
    }

    span {
      font-size: rem(13px);
      font-weight: $fw-semi-bold;
      color: $color-font-light;
      margin-right: 15px;
    }
  }

  &__image {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
}

.banner {
  @extend .widget;

  &__text {
    @extend .widget__text;
    left: unset;
    right: 0;
  }
}