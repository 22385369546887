.modal{
  &-content{
    border-radius: unset;
    background-color: $color-bg-extra;
    border: none;
  }
}
.modal-header .close span{
  font-size: rem(24px);
  line-height: 1.1;
}
.modal-backdrop.show {
  opacity: 0.8;
}