.subsite .widget-svg{
  position: relative;
  top: -20vw;
  z-index: 0;
  margin-bottom: -20vw;
  width: 100%;
  height: auto;
}

.widget-svg{
  position: relative;
  &__box{
    position: absolute;
    height: auto;
    background-color: $color-extra-opacity;
    transition: opacity .3s ease-in-out;
    padding: 1vw;
    opacity: 0;
    transform: scale(0);
    z-index: 1;
    @include mq($until: md){
      display: none;
    }
    p{
      margin-bottom: 0.3vw;
      font-size: 0.7vw;
    }
    h4{
      margin-bottom: 0.5vw;
      font-size: 1vw;
    }
    p, b, h4{
      color: $color-font-light;
    }
  }
  &__house{
    fill: transparent;
    cursor: pointer;
    transition: fill .3s ease-in-out;
    &:hover, &.active{
      fill: rgba(96, 128, 192, 0.7);
    }
  }
}