.footer {
  margin-top: 100px;
  position: relative;

  @include mq($until: xs) {
    margin-top: 50px;
  }

  &--house {
    margin-top: 0;
    @include mq($until: md){
      overflow: hidden;
    }

    img{
      @include mq($until: md){
        height: 400px;
        width: auto;
        max-width: unset;
      }
      @include mq($until: xs){
        height: auto;
        width: 100%;
      }
    }

    .footer__box {
      left: unset;
      right: 0;
      width: 30%;
      @include mq($until: md){
        width: auto;
      }
    }
    .footer__minibox{
      @include mq($until: lg){
        padding-left: 30px;
      }
    }
    .footer__minibox--estate, .footer__minibox--office{
      height: 50%;
    }
    .footer__minibox--estate{
      @include mq($from: xl) {
        height: 30%;
      }
    }
    .footer__minibox--office{
      @include mq($from: xl) {
        height: 70%;
      }
    }

  }

  &__box {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 9999;
    @include mq($until: xs) {
      position: static;
    }
  }

  &__minibox {
    padding-right: 50px;
    padding-top: 40px;
    padding-bottom: 40px;
    @include mq($until: xs) {
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 15px;
    }

    h3 {
      color: $color-font-light;
      font-size: rem(21px);
      margin-bottom: 25px;
    }

    a {
      @include mq($until: xs) {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }

    &--estate {
      height: 30%;
      background-color: $color-extra-opacity;
      @include mq($until: md) {
        height: 50%;
      }
    }

    &--office {
      height: 70%;
      background-color: $color-main-opacity;
      @include mq($until: md) {
        height: 50%;
      }
    }
  }
}

.footer-data {
  &__section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 25px;
    @include mq($until: xs) {
      margin-bottom: 0;
    }
  }

  &__icon svg {
    width: 24px;
    height: auto;
    margin-right: 10px;
    fill: $color-font-light;
  }

  &__content p {
    margin: 0;
    font-size: rem(16px);
    font-weight: $fw-regular;
    color: $color-font-light;
    line-height: 1.8;
  }

}

.footer-small {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 30px;
  @include mq($until: xs) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  span {
    transition: color .3s ease-in-out;
    font-weight: $fw-light;
  }

  a:hover span {
    color: $color-main;
  }

  &__copyright {
    @include mq($until: xs) {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }

  &__realization {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      margin-left: 10px;
    }
  }
}