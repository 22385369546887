.button {
  display: inline-block;
  color: $color-font-light;
  padding: 15px 20px;
  transition-property: color, background-color;
  transition-duration: .3s;
  transition-timing-function: ease-in-out;
  background-color: $color-main;
  line-height: 1;
  text-align: center;
  align-self: flex-start;
  cursor: pointer;
  font-weight: $fw-medium;
  border: none;

  &:hover {
    background-color: $color-extra;
    color: $color-font-light;
  }

  &--secondary {
    background-color: $color-extra;
    color: $color-font-light;

    &:hover {
      background-color: $color-main;
      color: $color-font-light;
    }
  }

  &--white {
    background-color: $color-font-light;
    color: $color-main;

    &:hover {
      color: $color-font-light;
      background-color: $color-extra;
    }
  }

  &--icon {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    padding: 10px 15px;

    &:hover {
      span {
        color: $color-font-light;
      }

      svg {
        fill: $color-font-light;
      }
    }

    span {
      font-size: rem(14px);
      color: $color-main;
      font-weight: $fw-regular;
      transition: color .3s ease-in-out;
    }

    svg {
      width: 24px;
      height: auto;
      margin-right: 15px;
      fill: $color-main;
    }
  }

  &--pdf {
    display: inline-flex;
    width: auto;
    background-color: $color-bg-extra;
    padding: 10px 35px 10px 15px;
    @include mq($until: md) {
      padding: 10px 20px 10px 15px;
    }

    span {
      font-size: rem(16px);
      font-weight: $fw-semi-bold;
      @include mq($until: lg) {
        font-size: rem(14px);
      }
    }

    svg {
      margin-right: 30px;
      @include mq($until: md) {
        margin-right: 15px;
      }
      @include mq($until: xs){
        width: 18px;
      }
    }
  }
}